import { toast } from 'react-toastify';
// import moment from 'moment';

export const handleRealoadPage = () => {
  window.location.reload();
};

export const userType = localStorage.getItem('userType') ?? '';

export const tripDetails = {
  id: 55,
  startDate: '2021-03-20T14:39:40.677',
  endDate: null,
  expectedEndDate: '2021-03-20T14:39:40.673',
  status: 1,
  totalOrders: 6,
  duration: 0,
  expectedDuration: 0,
};

export const timeFormatHHMM = (date) => {
  const dateFormat = new Date(date);
  const hh = String(dateFormat.getHours()).padStart(2, '0');
  const mm = String(dateFormat.getMinutes()).padStart(2, '0');
  const newTime = hh + ':' + mm;
  return newTime;
};

export const convertToISODateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toISOString().replace('T', ' ').replace('Z', '').slice(0, -4);
  return humanDateFormat;
};

export const convertToISOFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
  return newDate;
};

export const convertToDateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

export const convertToTimeOnly = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleTimeString([], {
    timeStyle: 'short',
  });
  return humanDateFormat;
};

export const dateFormatDDMM = (date) => {
  const dateFormat = new Date(date);
  let dd = String(dateFormat.getDate()).padStart(2, '0'),
    mm = String(dateFormat.getMonth() + 1).padStart(2, '0'),
    yyyy = dateFormat.getFullYear(),
    newDate = dd + '/' + mm + '/' + yyyy;
  return newDate;
};

export const dateFormatDDMMYYYY = (date) => {
  const dateFormat = new Date(date);
  let dd = String(dateFormat.getDate()).padStart(2, '0'),
    mm = String(dateFormat.getMonth() + 1).padStart(2, '0'),
    yyyy = dateFormat.getFullYear(),
    newDate = dd + '-' + mm + '-' + yyyy;
  return newDate;
};

export const convertPlanDateFormat = (date) => {
  const dateFormat = new Date(date);
  let dd = String(dateFormat.getDate()).padStart(2, '0'),
    mm = String(dateFormat.getMonth() + 1).padStart(2, '0'),
    yyyy = dateFormat.getFullYear(),
    newDate = yyyy + '-' + mm + '-' + dd;
  return newDate;
};

export const formatCustomDateTime = (dateStr) => {
  let formattedDateStr = dateStr;
  formattedDateStr = formattedDateStr?.replace(/Z/g, '');
  const dateTime = new Date(formattedDateStr);

  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes} ,${year}-${month}-${day} `;
};

export const orderStatus = {
  0: 'Picked',
  1: 'Delivering',
  2: 'Delivered',
  3: 'Canceled',
  4: 'Removed',
};

export const orderStatusIcon = {
  0: 'https://cdn.floward.com/web/Files/attachment/picked-637468243940293727.png',
  1: 'https://cdn.floward.com/web/Files/attachment/delivering-637468244443154127.png',
  2: 'https://cdn.floward.com/web/Files/attachment/delivered-637470804169416023.png',
  3: 'https://cdn.floward.com/web/Files/attachment/not-shared-637468243618625157.png',
  4: 'https://cdn.floward.com/web/Files/attachment/not-shared-637468243618625157.png',
};

export const driverTypes = [
  { id: 0, name: 'Insource' },
  { id: 1, name: 'Outsource' },
  { id: 2, name: 'Freelancer' },
];

export const planTypes = {
  0: 'System',
  1: 'CFR',
};

export const isDevelopmentEnvironment = process.env.REACT_APP_ENVIRONMENT === 'development';
export const isProductionEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production';
export const isStagingEnvironment = process.env.REACT_APP_ENVIRONMENT === 'staging';

export function excelToJSON(
  excelFile,
  setData,
  setIsValidList,
  checkValid,
  setDisableBtn,
  setLoading,
  setProcessMessage,
  setPrgressChunk,
  setExistingDriverPhones,
  setExistingDriverEmails
) {
  // var name = excelFile.name;
  setProcessMessage('');
  setPrgressChunk(0);
  setExistingDriverPhones([]);
  setExistingDriverEmails([]);
  const reader = new FileReader();
  setLoading(true);
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    import('xlsx')
      .then(({ read, utils }) => {
        const wb = read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
          defval: '',
          blankrows: false,
        });
        /* Update state */
        const newList = [];
        data.forEach((row) => {
          const rowArr = [];
          row.forEach(() => {
            rowArr.push(true);
          });
          newList.push(rowArr);
        });
        setIsValidList(newList);
        setData(data);
        setDisableBtn(false);
        checkValid(newList, data);
        setLoading(false);
      })
      .catch((e) => toast('An error occurred while loading the module (Excel to JSON)'));
  };
  reader.readAsBinaryString(excelFile);
}

export const isRowValid = (row) => {
  let isValid = true;
  const hasError = (element) => element === false;
  isValid = !row?.some(hasError);
  return isValid;
};

export const capitalize = (s) => {
  return s.trim().charAt(0).toUpperCase() + s.trim().toLowerCase().slice(1);
};

export const checkUserType = (type) => {
  let isTypeValid = false;
  driverTypes.forEach((driverType) => {
    if (capitalize(type) == driverType.name) {
      isTypeValid = true;
    }
  });
  return isTypeValid;
};

export const checkVehicleType = (type, vehicleList) => {
  let isTypeValid = false;
  vehicleList.forEach((vehicle) => {
    if (capitalize(type) == vehicle.vehicleName) {
      isTypeValid = true;
    }
  });
  return isTypeValid;
};

export const formatDrivers = (data, vehicleTypesList, opsId) => {
  const formatedData = data.map((d) => {
    return {
      OpsID: opsId,
      name: d[0],
      phone: d[1],
      type: driverTypes.filter((type) => type.name == capitalize(d[2]))[0].id,
      vehicleType: vehicleTypesList.filter((vehicle) => vehicle.vehicleName == capitalize(d[3]))[0]
        .vehicleType,
      email: d[4],
      password: d[5],
      company: d[6],
      carNumber: d[7],
      status: true,
    };
  });
  return formatedData;
};

export const downloadJSONFile = (fileSrc, fileName) => {
  const url = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(fileSrc))}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const formatDriversForCFR = (SelectedDrivers) => {
  const formatedData = SelectedDrivers.map((SelectedDriver) => {
    return {
      driverId: SelectedDriver.id,
      maxLoad: 5,
      driverName: SelectedDriver.name,
    };
  });
  return formatedData;
};

export const flagIcon = {
  trueIcon:
    'https://floward.imgix.net/web/Files/attachment/icons8-in_transit-15-637497660015920932.png',
  falseIcon:
    'https://floward.imgix.net/web/Files/attachment/icons8-in_transit-16-637497660137696407.png',
};

export const dropdownOptionStyle = {
  fontSize: '15px',
  padding: '9px 16px',
};

export const convertQueryParamsIntoObject = (params) =>
  JSON.parse(
    '{"' + decodeURIComponent(params.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  );

export const convertObjectIntoQueryParams = (object) =>
  '?' +
  Object.keys(object)
    .map((key) => {
      const filteredEncodedstring =
        object[key] && typeof object[key] === 'string'
          ? object[key].replace(/[\\]*["]*[?]*[<]*[>]*[|]*[\t]*/gi, '')
          : object[key];
      return `${key}=${encodeURIComponent(filteredEncodedstring)}`;
    })
    .join('&');

export async function downloadFile(fileSrc, fileName) {
  const url = window.URL.createObjectURL(new Blob([fileSrc]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export const coversTypeEnum = {
  0: 'لم يستلم',
  1: 'تم تسجيل دخول',
  2: 'تم تسجيل خروج',
};

export const coverNotedEnum = {
  0: 'كڤر مفقود',
  1: 'كڤر متهالك',
  2: 'مستثناه من الكڤر',
  3: 'بدون تليفون',
};

export const designCategoryEnum = {
  0: 'كلاسيكي ',
  1: 'مودرن',
};

export const localDateFormat = (date) => {
  const dateFormat = new Date(date);
  let dd = String(dateFormat.getUTCDate()).padStart(2, '0'),
    mm = String(dateFormat.getUTCMonth() + 1).padStart(2, '0'),
    yyyy = dateFormat.getUTCFullYear(),
    newDate = dd + '-' + mm + '-' + yyyy;
  return newDate;
};

export const localTimeFormat = (date) => {
  const dateFormat = new Date(date);
  const hh = String(dateFormat.getUTCHours()).padStart(2, '0');
  const mm = String(dateFormat.getUTCMinutes()).padStart(2, '0');
  const newTime = hh + ':' + mm;
  return newTime;
};
