import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { flagIcon } from '../../constants/Helpers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
import CloseIcon from '@material-ui/icons/Close';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import ProcessingLoader from '../shared/ProcessingLoader';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const UndeliveredModal = ({ isPopupOpen, setIsPopUpOpen, occasionId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: 'الاسم',
      field: 'name',
    },
    {
      title: 'رقم التليفون',
      field: 'phone',
    },
    {
      title: 'الرسالة النصيه',
      render: (rowData) =>
        rowData?.messageType === 0 ? (
          <img style={{ width: 30 }} src={flagIcon.falseIcon} />
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'رسالة البرشور ',
      render: (rowData) =>
        rowData?.messageType === 1 ? (
          <img style={{ width: 30 }} src={flagIcon.falseIcon} />
        ) : (
          <span>-</span>
        ),
    },
    {
      title: 'الباركود ',
      render: (rowData) => barCodeAttachments(rowData),
    },
    {
      title: 'لينك الاعتذار',
      render: (rowData) => (
        <a href={`https://dawabarcode.com/apologize/${rowData?.token}`} target="_blank">
          افتح
        </a>
      ),
    },
    {
      title: 'الحالة',
      render: (rowData) => (
        <span>{!rowData?.isTokenUsed ? 'غير محدد' : rowData?.isRejected ? 'معتذر' : 'تأكيد'}</span>
      ),
    },
    {
      title: 'اعادة ارسال',
      render: (rowData) => (
        <Button
          style={{ margin: 5, backgroundColor: '#22af47', color: 'white' }}
          variant="contained"
          endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
          onClick={() => openWhatsApp(rowData)}
        ></Button>
      ),
    },
  ];

  const barCodeAttachments = (rowData) => {
    return rowData?.qrCodes?.map((el, i) => (
      <a
        key={i}
        style={{ display: 'block' }}
        href={el?.brochureQrCodeUrl}
        target="_blank"
        rel="noreferrer"
        download
      >
        {el?.qrCode}
      </a>
    ));
  };

  const openWhatsApp = (rowData) => {
    const url = `https://web.whatsapp.com/send?phone=${
      rowData?.phone
    }&text=${rowData?.message?.replace(/\n/g, '%0A')}`;
    window.open(url, '_blank');
  };

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
  };

  const fetchUndeliveredNumbers = async (id) => {
    setIsLoading(true);
    try {
      const res = await AppService.getUndeliveredNumbers(id);
      setData(res?.data);
      setIsLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في الحصول علي الارقام');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUndeliveredNumbers(occasionId);
  }, []);

  return (
    <AppErrorBoundary>
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750, direction: 'rtl' }}
        className="add-driver-modal"
      >
        {isLoading ? (
          <div style={{ padding: 40 }}>
            <ProcessingLoader />
          </div>
        ) : (
          <DialogContent dividers>
            <MaterialDataGrid data={data} columns={columns} options={options} />
          </DialogContent>
        )}
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
            variant="contained"
          >
            اجمالي الرسائل: {data?.length}
          </Button>
          <Button
            autoFocus
            style={{ backgroundColor: 'gray', color: 'white', margin: 5 }}
            onClick={() => setIsPopUpOpen(false)}
          >
            الغاء
          </Button>
        </DialogActions>
      </Dialog>
    </AppErrorBoundary>
  );
};

export default UndeliveredModal;
