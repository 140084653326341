import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import CustomSelect from '../shared/CustomSelect';
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core';

const OccasionsHeader = ({ setOccasionsList, params, setParams }) => {
  const [active, setActive] = useState(1);
  const dispatch = useDispatch();

  const handleChangeOccasionName = (e) => {
    setParams((prev) => ({ ...prev, searchKey: e.target.value || null }));
  };

  const handleChangeActive = (id) => {
    if (id === 0) {
      setActive(0);
      setParams((prev) => ({ ...prev, isActive: null }));
    } else if (id === 1) {
      setActive(1);
      setParams((prev) => ({ ...prev, isActive: true }));
    } else if (id === 2) {
      setActive(2);
      setParams((prev) => ({ ...prev, isActive: false }));
    }
  };

  const getOccasionsList = () => {
    dispatch(setOccasionsList(params));
  };

  return (
    <Card className="card-wrapper">
      <CardContent>
        <div className="add-driver-modal">
          <form className="form">
            <Grid container>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label htmlFor="driver-name" className="input-label">
                    اسم المناسبة
                  </label>
                  <input
                    id="driver-name"
                    onChange={handleChangeOccasionName}
                    className="input"
                    type="text"
                    value={params?.searchKey}
                    placeholder="ابحث اسم المناسبة"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper select-input">
                  <label htmlFor="driver-type" className="input-label">
                    مناسبة فعاله
                  </label>
                  <AppErrorBoundary>
                    <CustomSelect
                      listData={[
                        { id: 1, name: 'نعم' },
                        { id: 2, name: 'لا' },
                        { id: 0, name: 'الكل' },
                      ]}
                      handleChange={(value) => handleChangeActive(value?.id)}
                      placeholder="Select a type"
                      selectedValue={active}
                      optionValue="id"
                      optionName="name"
                      disableClearIcon
                    />
                  </AppErrorBoundary>
                </div>
              </Grid>
              {/* <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label className="input-label" htmlFor="driver-sdate">
                    تاريخ من
                  </label>
                  <TextField
                    id="driver-sdate"
                    variant="outlined"
                    type="date"
                    className="date-picker"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label className="input-label" htmlFor="driver-sdate">
                    تاريخ الي
                  </label>
                  <TextField
                    id="driver-sdate"
                    variant="outlined"
                    type="date"
                    className="date-picker"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </div>
              </Grid> */}
              <Grid item sm={12}>
                <Button
                  style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
                  variant="contained"
                  onClick={getOccasionsList}
                >
                  بحث
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </CardContent>
    </Card>
  );
};

export default OccasionsHeader;
