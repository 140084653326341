import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//components
import OccasionsContent from './OccasionsContent';
import OccasionsHeader from './OccasionsHeader';
//actions
import { setOccasionsList } from '../../store/app/AppActions';

const OccasionsContainer = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({
    searchKey: null,
    isActive: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOccasionsList({ isActive: true }));
  }, []);

  return (
    <AppErrorBoundary>
      <OccasionsHeader setOccasionsList={setOccasionsList} params={params} setParams={setParams} />
      <OccasionsContent
        isLoaderOpen={isLoaderOpen}
        setIsLoaderOpen={setIsLoaderOpen}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </AppErrorBoundary>
  );
};

export default OccasionsContainer;
