//api end points
export const endPoints = {
  //optimize
  getReadyOrders: `hub/order/ready`,
  getDoOptimize: `optimizer`,
  getConfirmOptimization: `optimizer/confirm`,
  //drivers
  getDriversList: `drivers/driver-list`,
  getDriverInfoById: `drivers/{0}`,
  getDriverOrdersList: `drivers/{0}/orders`,
  getOrderDetailsInfo: `order/hub/orders/{0}/details`,
  getOrderCartDetails: `order/hub/cart/{0}/cartdetails`,
  driverData: `drivers/hub/driver`,
  getDriversVehiclesTypes: `drivers/vehicles`,
  bulkUploadDrivers: `drivers/bulk-upload-drivers`,
  //trips
  getTripsList: `trips`,
  getTripDetailsData: `trips/{0}/orders`,
  getTripListBySearch: `trips/search`,
  //app
  getOpsInfo: `opscenter/{0}`,
  getAllOpsCenters: `hub/ops`,
  //dispatch
  getValidateOrder: `order/scan/{0}`,
  //googleLMFS
  getGoogleFleetToken: `drivers/lmfs/create-token`,
  getGoogleDriversList: `drivers/hub/trips/get-vehicles`,
  getGoogleTasksList: `drivers/hub/trips/get-tasks`,
  getPaginatedTasksList: `drivers/hub/trips/get-tasks-paginated`,
  //dispatch planned
  getValidateGroupOrders: `/order/hub/logistics/validate-list-before-assign`,
  getConfirmDispatchGroup: `/drivers/hub/trips/bulk-assign-orders`,
  //saved plans list
  getSavedPlansList: `drivers/plans-list`,
  getPlanDetails: `drivers/plan-details/{0}`,
  deletePlan: `drivers/delete-plan/{0}`,
  assignDriverToGroup: `drivers/update-driver/{0}`,
  //planning
  exportToCFR: `/order/hub/logistics/cfr/list`,
  getTimeslots: `drivers/delivery-slots-list`,
  uploadFile: `drivers/upload-file-from-cfr`,
  getOrdersByTimeslot: `order/hub/logistics/planing/list`,
  createPlan: `drivers/create-plan`,
  updatePlan: `drivers/update-plan/{0}`,

  //occasions
  getOccasionsList: 'Occasion',
  getEditOccasion: 'Occasion/{0}',
  getScannedList: `invitee/scanned/{0}`,
  scanQrCode: `invitee/scan`,
  searchByPhone: `Invitee/search/{0}`,
  sendWhatapp: `Invitee/send-whatsapp`,
  sendBorchor: `Invitee/send-whatsapp/brochure`,
  getUserByToken: `Invitee/{0}`,
  getConfirmAttend: `Invitee/respond/{0}`,
  getDataByOwner: `Occasion/owner/{0}`,
  editInvitee: `Invitee/{0}`,
  addNewInvitee: `Invitee`,
  getLogin: `Identity/login`,
  getExportPdf: `Invitee/scanned/{0}/export`,
  getPollingData: `Occasion/owner/{0}/poll`,
  getPollByOccasionId: `Occasion/{0}/poll`,
  getUndeliveredNumbers: `invitee/unsent/{0}`,
  deleteOccasionById: `Occasion/{0}`,
  //covers
  getScannedInvitees: `occasion/covers/{0}`,
  getCheckIn: `Invitee/covers/check-in/{0}`,
  getCheckOutById: `Invitee/covers/checkout/{0}`,
  getCheckOutByCover: `Invitee/covers/checkout`,
  getCoverNotes: `Invitee/covers/note/{0}`,
  getExportCoverPdf: `/Invitee/covers/{0}/export`,
  getCoversDataByToken: `Occasion/owner/{0}/covers`,
  //apologize message
  getApologizeMessage: `Invitee/respond/{0}/message`,
  //gallery
  getGalleryData: `Brochure`,
  updateGalleryById: `Brochure/{0}`,
};
