import React from 'react';
import { useSelector } from 'react-redux';
import { flagIcon, dateFormatDDMM } from '../../constants/Helpers';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
//material ui
import { useNavigate } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
//shared
import SectionLoader from '../shared/SectionLoader';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//selectors
import { getOccasionsList } from '../../store/app/AppSelectors';

const OccasionsContent = ({ isLoaderOpen }) => {
  const navigate = useNavigate();
  const occasionsList = useSelector((state) => getOccasionsList({ state }));

  const columns = [
    {
      title: 'رقم المناسبة',
      field: 'id',
    },
    {
      title: 'الدعوة',
      field: 'name',
    },
    {
      title: 'تاريخ المناسبة',
      field: 'date',
      render: (rowData) => dateFormatDDMM(rowData?.date),
    },
    {
      title: 'عدد الدعوات',
      field: 'totalNumberOfAttendees',
    },
    {
      title: 'المكان',
      field: 'count',
      render: (rowData) => (
        <p
          style={{ textDecoration: 'underline' }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              `https://www.google.com/maps/?q=${rowData?.latitude},${rowData?.longitude}`
            );
          }}
        >
          {rowData?.addressName}
        </p>
      ),
    },
    {
      title: 'فعال/غير فعال',
      render: (rowData) => (
        <img
          style={{ width: 30 }}
          src={rowData.isActive ? flagIcon.trueIcon : flagIcon.falseIcon}
        />
      ),
    },
    {
      title: 'تصميم الدعوة',
      field: 'brochureUrl',
      render: (rowData) => (
        <img
          onClick={(e) => {
            e.stopPropagation();
            window.open(rowData?.brochureUrl);
          }}
          style={{ width: 40 }}
          src={rowData?.brochureUrl}
        />
      ),
    },
  ];

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: true,
    sorting: false,
    exportButton: true,
  };

  const onRowClick = (evt, row) => {
    navigate(`/edit-occasion/${row?.id}`);
  };

  return (
    <AppErrorBoundary>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <TableContainer component={Paper}>
          {occasionsList?.length > 0 ? (
            <MaterialDataGrid
              data={occasionsList}
              columns={columns}
              options={options}
              onRowClick={onRowClick}
            />
          ) : (
            <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto' }}>
              لا يوجد مناسبات
            </p>
          )}
        </TableContainer>
      )}
    </AppErrorBoundary>
  );
};

export default OccasionsContent;
