import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userType } from '../../../constants/Helpers';
//components
import Drawer from '@material-ui/core/Drawer';
import BulkUploadDriversModal from '../../modals/BulkUploadDriversModal';
import AppErrorBoundary from '../../shared/AppErrorBoundary';
//selectors
import {
  getIsRightSideBarOpen,
  getIsBulkUploadDriversModalOpen,
  getIsLoggedIn,
} from '../../../store/app/AppSelectors';

const RightSidebar = ({ history }) => {
  const isRightSideBarOpen = useSelector((state) => getIsRightSideBarOpen({ state }));
  const isBulkUploadDriversModalOpen = useSelector((state) =>
    getIsBulkUploadDriversModalOpen({ state })
  );
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    dispatch({
      type: 'SET_IS_LOGGED_IN_FALSE',
    });
    window.location.href = '/login';
  };

  return (
    <>
      <Drawer
        className="right-drawer"
        anchor={'right'}
        variant={'persistent'} //removes the overlay
        open={isLoggedIn && isRightSideBarOpen}
      >
        <div
          className="right-sidebar"
          //  style={{ display: 'none' }}
        >
          <div className="right-sidebar-section">
            {userType == 1 && (
              <>
                <button
                  className="right-sidebar-button"
                  onClick={() => {
                    navigate('/home');
                  }}
                >
                  الرئيسية
                </button>
                <button
                  className="right-sidebar-button"
                  onClick={() => {
                    navigate('/occasions');
                  }}
                >
                  المناسبات
                </button>
                <button
                  className="right-sidebar-button"
                  onClick={() => {
                    navigate('/add-occasion');
                  }}
                >
                  اضافة مناسبة جديدة
                </button>
                <button
                  className="right-sidebar-button"
                  onClick={() => {
                    navigate('/dawa-gallery');
                  }}
                >
                  صفحة التصاميم
                </button>
              </>
            )}
            <button
              className="right-sidebar-button"
              onClick={() => {
                navigate('/attendance');
              }}
            >
              التحضير
            </button>
            <button
              className="right-sidebar-button"
              onClick={() => {
                navigate('/covers');
              }}
            >
              الكڤرات
            </button>
            <button className="right-sidebar-button" onClick={handleLogOut}>
              تسجيل الخروج
            </button>
          </div>
        </div>
      </Drawer>
      {isBulkUploadDriversModalOpen && (
        <AppErrorBoundary>
          <BulkUploadDriversModal />
        </AppErrorBoundary>
      )}
    </>
  );
};

export default RightSidebar;
