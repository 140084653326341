import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CustomSelect from '../../components/shared/CustomSelect';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ left: 8 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AddBorchurDesignModal = ({
  selectedDesign,
  isPopupOpen,
  setIsPopUpOpen,
  setSelectedDesign,
  fetchGalleryData,
  params,
}) => {
  const [designImg, setDesignImg] = useState('');
  const [category, setCategory] = useState('');
  const [rate, setRate] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    if (selectedDesign && Object.keys(selectedDesign).length > 0) {
      setDesignImg(selectedDesign?.image ?? '');
      setCategory(selectedDesign?.category ?? '');
      setRate(selectedDesign?.title ?? 0);
    }
  }, [selectedDesign]);

  const handleSubmit = async () => {
    const data = {
      image: designImg,
      category,
      title: rate,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    setDisableBtn(true);
    try {
      selectedDesign
        ? await AppService.getUpdateDesign(formData, selectedDesign?.id)
        : await AppService.getAddNewDesign(formData);
      setTimeout(() => {
        fetchGalleryData(params);
      }, 200);
      setDisableBtn(false);
      setIsPopUpOpen(false);
      toast.success('تم اضافة التصميم بنجاح');
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'حدث خطأ');
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedDesign('');
    };
  }, []);

  return (
    <div>
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750, direction: 'rtl' }}
        className="add-driver-modal"
      >
        <DialogTitle
          style={{ padding: 10 }}
          id="customized-dialog-title"
          onClose={() => setIsPopUpOpen(false)}
        >
          {selectedDesign ? 'تعديل التصميم' : 'اضافة تصميم جديد'}
        </DialogTitle>
        <DialogContent dividers>
          <form className="form">
            <div className="input-wrapper">
              <label htmlFor="driver-name" className="input-label">
                رفع التصميم
              </label>
              <input
                id="driver-photo"
                onChange={(e) => setDesignImg(e.target.files[0])}
                className="input"
                type="file"
                accept="image/*"
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="driver-phone" className="input-label">
                تصنيف التصميم
              </label>
              <CustomSelect
                listData={[
                  { id: 0, name: 'كلاسيك' },
                  { id: 1, name: 'مودرن' },
                ]}
                handleChange={(value) => setCategory(value?.id)}
                placeholder="تصنيف التصميم"
                selectedValue={category}
                optionValue="id"
                optionName="name"
                disableClearIcon
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="driver-phone" className="input-label">
                التقييم
              </label>
              <input
                id="driver-phone"
                onChange={(e) => setRate(e.target.value)}
                placeholder="تقييم التصميم "
                className="input"
                type="number"
                value={rate}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button autoFocus onClick={() => setIsPopUpOpen(false)}>
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#22af47', color: 'white' }}
            variant="contained"
            onClick={handleSubmit}
            disable={disableBtn}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddBorchurDesignModal;
