import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, Divider } from '@material-ui/core';
import { flagIcon } from '../../constants/Helpers';
import ProcessingLoader from '../shared/ProcessingLoader';
import AppService from '../../services/AppService';

const RemoveGalleryDesignModal = ({
  isPopupOpen,
  setIsPopUpOpen,
  fetchGalleryData,
  selectedDesign,
  setSelectedDesign,
  params,
}) => {
  const [isLoading, setIsLoading] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await AppService.getRemoveDesign(selectedDesign?.id);
      toast.success('تم حذف التصميم بنجاح');
      setTimeout(() => {
        fetchGalleryData(params);
      }, 200);
      setIsPopUpOpen(false);
      setIsLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في حذف التصميم');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedDesign('');
    };
  }, []);

  return (
    <div className="">
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopUpOpen((prev) => !prev)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ProcessingLoader /> <span>جاري الحذف ...</span>
          </div>
        ) : (
          <>
            <div
              style={{
                padding: 30,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={flagIcon.falseIcon} style={{ width: 30, marginRight: 5 }} />
              تأكيد حذف التصميم؟
            </div>
            <Divider />
          </>
        )}
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => setIsPopUpOpen(false)}
            color="default"
          >
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoveGalleryDesignModal;
