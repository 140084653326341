import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography, DialogContent, IconButton, TextField, Grid } from '@material-ui/core';
import CustomSelect from '../shared/CustomSelect';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PollingModal = ({ isPollModalOpen, setIsPollModalOpen, occasionId }) => {
  const [pollingData, setPollingData] = useState({});
  const [allowedNames, setAllowedNames] = useState('');
  const [isKidsAllowed, setIsKidsAllowed] = useState('');
  const [isServantAllowed, setIsServantAllowed] = useState('');
  const [duplicationEntry, setDuplicationEntry] = useState('');
  const [contactDetails, setContactDetails] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [organizer, setOrganizer] = useState(2);

  useEffect(() => {
    if (pollingData && Object.keys(pollingData).length > 0) {
      setAllowedNames(pollingData?.allowedNames ?? '');
      setIsKidsAllowed(pollingData?.kidsAllowed ?? '');
      setIsServantAllowed(pollingData?.servantsAllowed ?? '');
      setDuplicationEntry(pollingData?.duplicateInvitationEntry ?? '');
      setContactDetails(pollingData?.contactDetails ?? '');
      setAdditionalDetails(pollingData?.additionalDetails ?? '');
      setOrganizer(pollingData?.organizer ?? 2);
    }
  }, [pollingData]);

  const fetchPollingData = async () => {
    try {
      const res = await AppService.getPollByOccasionId(occasionId);
      setPollingData(res?.data);
    } catch (err) {
      // toast.error(err?.response?.data?.message ?? 'فشل الحصول علي بيانات الاستبيان');
    }
  };

  useEffect(() => {
    fetchPollingData();
  }, []);

  return (
    <Dialog
      className="add-driver-modal polling-modal"
      onClose={() => setIsPollModalOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={isPollModalOpen}
      // style={{ maxHeight: 750 }}
    >
      <DialogTitle onClose={() => setIsPollModalOpen(false)}>بيانات الاستبيان</DialogTitle>
      <DialogContent dividers>
        <form className="form">
          <Grid dir="rtl">
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  الاسماء المسموح لهم بالحضور بدون دعوات
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={allowedNames}
                  onChange={(e) => setAllowedNames(e.target.value)}
                  disabled
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  هل مسموح بدخول الاطفال؟
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'نعم' },
                      { id: 1, name: 'لا' },
                      { id: 2, name: 'اسماء محدده' },
                    ]}
                    handleChange={(value) => setIsKidsAllowed(value?.id)}
                    placeholder="اختر"
                    selectedValue={isKidsAllowed}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                    isDisabled={true}
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  هل مسموح بدخول الخدم؟
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'نعم' },
                      { id: 1, name: 'لا' },
                      { id: 2, name: 'اسماء محدده' },
                    ]}
                    handleChange={(value) => setIsServantAllowed(value?.id)}
                    placeholder="اختر"
                    selectedValue={isServantAllowed}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                    isDisabled={true}
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  طريقة التعامل مع الحضور بدون دعوة
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'لا يسمح لهم بالدخول نهائيا' },
                      { id: 1, name: 'التواصل مع اصحاب الحفلة' },
                      { id: 2, name: 'طبقا للتعليمات' },
                    ]}
                    handleChange={(value) => setDuplicationEntry(value?.id)}
                    placeholder="اختر"
                    selectedValue={duplicationEntry}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                    isDisabled={true}
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  اختيار المنظمين
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'شباب' },
                      { id: 1, name: 'بنات' },
                      { id: 2, name: 'شاب و بنت' },
                    ]}
                    handleChange={(value) => setOrganizer(value?.id)}
                    placeholder="اختر"
                    selectedValue={organizer}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                    isDisabled={true}
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  اسم و رقم التواصل
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={contactDetails}
                  onChange={(e) => setContactDetails(e.target.value)}
                  disabled
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  تعليمات اضافيه / اسماء مستثناه من الكڤرات
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  disabled
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PollingModal;
