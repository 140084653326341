import React from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';

const ApologizeMessageModal = ({ isPopupOpen, setIsPopUpOpen, apologizeMessage, inviteeName }) => {
  return (
    <div className="">
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopUpOpen((prev) => !prev)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className="input-wrapper"
          style={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'Column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
          }}
        >
          <label className="input-label" htmlFor="driver-sdate" style={{ fontWeight: 'bold' }}>
            {` اسم المرسل /  ${inviteeName}  `}
          </label>
          <div>
            <p className="welcome-msg">"{apologizeMessage}"</p>
          </div>
        </div>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => setIsPopUpOpen(false)}
            color="default"
          >
            عوده
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApologizeMessageModal;
