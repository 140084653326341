import React, { useState, useEffect } from 'react';
import { formatCustomDateTime, flagIcon } from '../../constants/Helpers';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import CheckInModal from '../../components/modals/CheckInModal';
import QrReader from 'react-qr-reader2';
//helpers
import { convertQueryParamsIntoObject, downloadFile } from '../../constants/Helpers';
//material ui
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
//shared
import SectionLoader from '../shared/SectionLoader';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//api
import AppService from '../../services/AppService';

const AttendanceContent = ({ setScannedList, scannedList, isLoading }) => {
  const [updatedScannedList, setUpdatedScannedList] = useState([]);
  const [isShowCount, setIsShowCount] = useState(false);
  const [occasionId, setOccasionId] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [selectedCover, setSelectedCover] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const location = useLocation();

  const fetchScannedAttendece = async (occasion) => {
    try {
      const res = await AppService.getScannedList(occasion);
      setScannedList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: 'رقم الدعوة',
      field: 'qrCode',
    },
    {
      title: 'الاسم',
      field: 'name',
    },
    {
      title: 'رقم التليفون',
      field: 'phone',
    },
    {
      title: 'تاريخ الدخول',
      field: 'attendanceDate',
      render: (rowData) => <span>{formatCustomDateTime(rowData?.attendanceDate)}</span>,
    },
    {
      title: 'تسجيل دخول الكڤر ',
      field: 'attendanceDate',
      render: (rowData) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {rowData?.coverStatus === 0 ? (
            <>
              <Button
                style={{ backgroundColor: '#006782', marginBottom: 5 }}
                onClick={() => {
                  setSelectedCover(rowData);
                  setIsCameraOpen(true);
                  setIsScanned(false);
                }}
                variant="contained"
                color="primary"
                endIcon={<CameraAltIcon style={{ marginRight: 8 }} />}
                size="small"
              >
                مسح الباركود
              </Button>
              <Button
                style={{
                  backgroundColor: '#22af47',
                  color: 'white',
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  setSelectedCover(rowData);
                  setIsCheckInModalOpen(true);
                }}
              >
                تسجيل دخول
              </Button>
            </>
          ) : (
            <img src={flagIcon.trueIcon} style={{ width: 30 }} />
          )}
        </div>
      ),
    },
  ];

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    toolbar: true,
  };

  //actions
  const actions = [
    {
      icon: () => (
        <Button
          onClick={() => setIsShowCount((prev) => !prev)}
          style={{ backgroundColor: '#22af47' }}
          variant="contained"
          color="primary"
          size="small"
        >
          اجمالي الحضور{' '}
          {isShowCount ? updatedScannedList?.flatMap((entry) => entry?.qrCodes)?.length : ''}
        </Button>
      ),
      isFreeAction: true,
    },
    {
      icon: () => (
        <Button
          onClick={handleExportPdf}
          variant="contained"
          color="success"
          size="small"
          disabled={disableBtn}
        >
          تقرير المناسبة
        </Button>
      ),
      isFreeAction: true,
    },
  ];

  useEffect(() => {
    const updatedList = scannedList.flatMap((attendee) =>
      attendee?.qrCodes?.map((qrCode) => ({
        name: attendee?.name,
        phone: attendee?.phone,
        qrCode: qrCode?.qrCode,
        attendanceDate: qrCode?.attendanceDate,
        coverId: qrCode?.coverId,
        coverStatus: qrCode?.phoneCoverStatus,
      }))
    );

    setUpdatedScannedList(updatedList);
  }, [scannedList]);

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location?.search);
      setOccasionId(parseInt(queryObj?.occasionId), 10);
    }
  }, [location?.search]);

  const handleExportPdf = async () => {
    setDisableBtn(true);
    try {
      const res = await AppService.getExportPdf(occasionId);
      downloadFile(res?.data, 'report.pdf');
      setDisableBtn(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في تحميل التقرير ');
      setDisableBtn(false);
    }
  };

  const handleScanCover = async (data) => {
    if (data) {
      if (isScanned) {
        return;
      }
      setIsCameraOpen(false);
      setIsScanned(true);
      let dataBody = {
        coverNumber: data,
      };
      try {
        const res = await AppService.getCheckIn(dataBody, selectedCover?.coverId);
        setTimeout(() => {
          fetchScannedAttendece(occasionId);
        }, 100);
        toast.success(res?.data?.message ?? 'تم إضافة الكڤر بنجاح');
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  return isCameraOpen ? (
    <div className="camera-wrapper" style={{ marginTop: 20 }}>
      <QrReader
        delay={500}
        facingMode="environment"
        legacyMode={true}
        style={{
          width: '100%',
          height: '100%',
        }}
        onError={handleError}
        onScan={handleScanCover}
      />
      <Button
        onClick={() => {
          setIsCameraOpen(false);
        }}
        variant="contained"
        color="secondary"
        className="camera-close-btn"
      >
        قفل
      </Button>
    </div>
  ) : (
    <>
      {isCheckInModalOpen && (
        <CheckInModal
          isPopupOpen={isCheckInModalOpen}
          setIsPopUpOpen={setIsCheckInModalOpen}
          selectedCover={selectedCover}
          occasionId={occasionId}
          setSelectedCover={setSelectedCover}
          isAttendancePage
          fetchScannedAttendece={fetchScannedAttendece}
        />
      )}
      <div className="attendence-wrapper">
        <AppErrorBoundary>
          {isLoading ? (
            <SectionLoader />
          ) : (
            <TableContainer component={Paper}>
              {scannedList?.length > 0 ? (
                <MaterialDataGrid
                  data={updatedScannedList}
                  columns={columns}
                  options={options}
                  actions={actions}
                />
              ) : (
                <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto' }}>
                  لا يوجد حضور
                </p>
              )}
            </TableContainer>
          )}
        </AppErrorBoundary>
      </div>
    </>
  );
};

export default AttendanceContent;
