import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import CustomSelect from '../shared/CustomSelect';
import { Card, Grid } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LoopIcon from '@material-ui/icons/Loop';
import Button from '@material-ui/core/Button';
import QrReader from 'react-qr-reader2';
import { toast } from 'react-toastify';
import {
  flagIcon,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  userType,
} from '../../constants/Helpers';
import PollingModal from '../modals/PollingModal';
//api
import AppService from '../../services/AppService';

const AttendanceHeader = ({ setScannedList, occasionsList, setIsLoading }) => {
  const [occasion, setOccasion] = useState('');
  const [invNo, setInvNo] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMesaage] = useState('');
  const [scanIcon, setScanIcon] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isPollModalOpen, setIsPollModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (location?.search) {
        const queryObj = convertQueryParamsIntoObject(location?.search);
        setOccasion(parseInt(queryObj?.occasionId), 10);
        await fetchScannedList(queryObj?.occasionId);
        inputRef.current.focus();
      }
    };
    fetchData();
  }, []);

  const handleScanOrder = async (data) => {
    if (data) {
      if (isScanned) {
        return;
      }
      setIsCameraOpen(false);
      setIsScanned(true);
      setInvNo(data);
      const dataBody = {
        qrCode: data,
        occasionId: occasion,
      };
      try {
        await AppService.getScanQrCode(dataBody);
        setTimeout(() => {
          fetchScannedList(occasion);
        }, 200);
        setScanIcon('true');
        setErrorMesaage('');
        setInvNo('');
        inputRef.current.focus();
      } catch (err) {
        setErrorMesaage(err?.response?.data?.message);
        setScanIcon('false');
        setInvNo('');
        inputRef.current.focus();
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  const fetchScannedList = async (occasionId) => {
    try {
      const res = await AppService.getScannedList(occasionId);
      setScannedList(res?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'لا توجد دعوات ');
    }
  };

  const handleOccasionChange = async (value) => {
    setOccasion(value?.id);
    setIsLoading(true);
    await fetchScannedList(value?.id);
    setIsLoading(false);
    const queryParams = { occasionId: value?.id };
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: '/attendance',
      search: queryString,
    });
  };

  const handleManualScan = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const dataBody = {
        qrCode: e?.target?.value,
        occasionId: occasion,
      };
      try {
        await AppService.getScanQrCode(dataBody);
        setTimeout(() => {
          fetchScannedList(occasion);
        }, 200);
        setScanIcon('true');
        setErrorMesaage('');
        setInvNo('');
        inputRef.current.focus();
      } catch (err) {
        setErrorMesaage(err?.response?.data?.message);
        setScanIcon('false');
        setInvNo('');
        inputRef.current.focus();
      }
    }
  };

  const handleSearchByPhone = async (e) => {
    if (e.key === 'Enter') {
      if (e?.target?.value?.length >= 8) {
        e.preventDefault();
        setSearchResult('');
        const dataBody = {
          searchKey: e?.target?.value,
        };
        try {
          const res = await AppService.getSearchByPhone(dataBody, occasion);
          setSearchResult(
            res?.data?.qrCodes.filter((el) => !el.isQrCodeUsed)?.map((item) => item.qrCode)
          );
        } catch (err) {
          toast.error(err?.response?.data?.message ?? 'رقم التليفون غير موجود');
        }
      } else toast.error('رقم التليفون غير صحيح');
    }
  };

  const handleScanResult = async (el) => {
    const dataBody = {
      qrCode: el,
      occasionId: occasion,
    };
    setInvNo(el);
    try {
      await AppService.getScanQrCode(dataBody);
      setTimeout(() => {
        fetchScannedList(occasion);
      }, 200);
      setScanIcon('true');
      setErrorMesaage('');
      setInvNo('');
      inputRef.current.focus();
    } catch (err) {
      setErrorMesaage(err?.response?.data?.message);
      setInvNo('');
      setScanIcon('false');
      inputRef.current.focus();
    }
  };

  const attendeceList = () => {
    const attendeceList = occasionsList?.filter((el) => el?.parentId === 0);
    if (userType == 1) return attendeceList;
    else {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setUTCDate(today.getUTCDate() + 1);
      const todayUTC = new Date(
        Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
      );
      const tomorrowUTC = new Date(
        Date.UTC(tomorrow.getUTCFullYear(), tomorrow.getUTCMonth(), tomorrow.getUTCDate())
      );

      return attendeceList?.filter((el) => {
        const elDate = new Date(el?.date);
        const elDateUTC = new Date(
          Date.UTC(elDate.getUTCFullYear(), elDate.getUTCMonth(), elDate.getUTCDate())
        );
        return (
          elDateUTC.getTime() === todayUTC.getTime() ||
          elDateUTC.getTime() === tomorrowUTC.getTime()
        );
      });
    }
  };

  const redirectGreetingsPage = () => {
    window.location.href = `/greetings?occasion=${occasion}`;
  };

  return (
    <>
      {isCameraOpen ? (
        <div className="camera-wrapper" style={{ marginTop: 20 }}>
          <QrReader
            delay={500}
            facingMode="environment"
            legacyMode={true}
            style={{
              width: '100%',
              height: '100%',
            }}
            onError={handleError}
            onScan={handleScanOrder}
          />
          <Button
            onClick={() => {
              setIsCameraOpen(false);
            }}
            variant="contained"
            color="secondary"
            className="camera-close-btn"
          >
            قفل
          </Button>
        </div>
      ) : (
        <>
          <Card className="card-wrapper">
            <div className="add-driver-modal">
              <form className="form" style={{ padding: '10px 0' }}>
                <Grid>
                  <Grid item md={4} sm={12} className="input-wrapper select-input">
                    <label className="input-label">اسم المناسبة</label>
                    <AppErrorBoundary>
                      <CustomSelect
                        listData={attendeceList()}
                        // listData={ occasionsList?.filter((el) => el?.parentId === 0)}
                        handleChange={(value) => handleOccasionChange(value)}
                        placeholder="اختر اسم المناسبة"
                        selectedValue={occasion}
                        optionValue="id"
                        optionName="name"
                        disableClearIcon
                      />
                    </AppErrorBoundary>
                  </Grid>
                  {occasion && (
                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                      <Grid item md={2} sm={12} className="input-wrapper select-input">
                        <Button
                          color="primary"
                          style={{ backgroundColor: '#006782' }}
                          variant="contained"
                          size="small"
                          onClick={() => setIsPollModalOpen(true)}
                        >
                          الاستبيان الخاص بالمناسبة
                        </Button>
                      </Grid>
                      <Grid item md={2} sm={12} style={{ padding: 0 }}>
                        <Button
                          color="primary"
                          style={{ backgroundColor: '#006782' }}
                          variant="contained"
                          size="small"
                          onClick={redirectGreetingsPage}
                        >
                          صفحة الستاند
                        </Button>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </form>
            </div>
          </Card>
          <Card className="card-wrapper">
            <div className="add-driver-modal">
              <form className="form" style={{ padding: '10px 0' }}>
                <Grid
                  container
                  className="input-wrapper select-input"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="driver-type" className="input-label">
                      الباركود / رقم الدعوة
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="driver-name"
                        onChange={(e) => setInvNo(e.target.value)}
                        onKeyPress={handleManualScan}
                        className="input"
                        type="number"
                        value={invNo}
                        placeholder=" رقم الدعوة"
                        disabled={!occasion}
                        ref={inputRef}
                      />
                      {scanIcon && (
                        <img
                          src={scanIcon === 'true' ? flagIcon.trueIcon : flagIcon.falseIcon}
                          style={{ width: 45, padding: 5 }}
                        />
                      )}
                      {errorMessage && <p style={{ color: 'red', fontSize: 15 }}>{errorMessage}</p>}
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="driver-type" className="input-label">
                      ابحث برقم التليفون
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        onKeyPress={handleSearchByPhone}
                        className="input"
                        type="number"
                        value={phone}
                        placeholder=" رقم التليفون"
                        disabled={!occasion}
                      />
                      <LoopIcon
                        onClick={() => {
                          setSearchResult('');
                          setPhone('');
                        }}
                        style={{ marginRight: 5, cursor: 'pointer' }}
                      />
                    </div>
                  </Grid>
                  <Grid style={{ textAlign: 'end' }} item md={3} sm={12}>
                    <Button
                      style={{ backgroundColor: '#006782', marginTop: 30 }}
                      onClick={() => {
                        setIsCameraOpen(true);
                        setIsScanned(false);
                      }}
                      variant="contained"
                      color="primary"
                      endIcon={<CameraAltIcon style={{ marginRight: 8 }} />}
                      size="small"
                      disabled={!occasion}
                    >
                      مسح الباركود
                    </Button>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    {searchResult?.length > 0 && (
                      <p style={{ color: 'red', fontWeight: 'bold' }}>
                        <p style={{ textDecoration: 'underline' }}>الدعوات: </p>
                        {searchResult?.map((el, i) => (
                          <>
                            <span
                              key={i}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleScanResult(el)}
                            >
                              {el}
                            </span>{' '}
                            {searchResult?.length - 1 !== i && <span> - </span>}
                          </>
                        ))}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </form>
            </div>
          </Card>
        </>
      )}
      {isPollModalOpen && (
        <PollingModal
          isPollModalOpen={isPollModalOpen}
          setIsPollModalOpen={setIsPollModalOpen}
          occasionId={occasion}
        />
      )}
    </>
  );
};

export default AttendanceHeader;
